import { IRoute } from "./interfaces/route";
import CreatePassword from "./pages/auth/CreatePassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import Register from "./pages/auth/Register";
import Verify from "./pages/auth/Verify";
import Company from "./pages/dash/Company";
import Home from "./pages/dash/Home";
import JobList from "./pages/dash/JobList";
import JobNew from "./pages/dash/JobNew";
import Settings from "./pages/dash/Settings";

export const authRoutes: IRoute[] = [
  { element: <Login />, path: "/auth/login" },
  { element: <Logout />, path: "/auth/logout" },
  { element: <Register />, path: "/auth/create-account" },
  { element: <CreatePassword />, path: "/auth/create-password" },
  { element: <ForgotPassword />, path: "/auth/forgot-password" },
  { element: <Verify />, path: "/auth/verify" },
];

export const dashRoutes: IRoute[] = [
  { element: <Home />, path: "/" },
  { element: <JobList />, path: "/jobs" },
  { element: <JobNew />, path: "/jobs/add" },
  { element: <Settings />, path: "/settings" },
  { element: <Company />, path: "/company" },
];
