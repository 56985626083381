import {
  Anchor,
  Button,
  Flex,
  Paper,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import LogoDark from "../../components/LogoDark";

const Login = () => {
  return (
    <Flex rowGap={15} align={"center"} direction={"column"}>
      <LogoDark />
      <Paper maw={320} bg={"transparent"}>
        <Text size="16px" fw={600} mb={5} ta={"center"}>
          Welcome Back!
        </Text>
        <Text ta={"center"} mb={15}>
          Please provide your credentials to login.
        </Text>
        <TextInput
          radius={8}
          placeholder="business email"
          label="Email Address"
          mb={15}
        />
        <PasswordInput
          radius={8}
          label="Password"
          placeholder="enter password"
          mb={15}
        />
        <Button radius={8} mb={20} fw={500} color="#1D212C" fullWidth>
          Login
        </Button>
        <Flex direction={"column"} align={"center"} rowGap={10}>
          <Anchor
            href="/auth/forgot-password"
            fw={600}
            underline="never"
            c={"#1D212C"}
          >
            Forgot Password?
          </Anchor>
          <Text>
            Don't you have an account?{"  "}
            <Anchor
              href="/auth/create-account"
              c={"#1D212C"}
              underline="never"
              fw={600}
            >
              Create Account
            </Anchor>
          </Text>
        </Flex>
      </Paper>
    </Flex>
  );
};

export default Login;
