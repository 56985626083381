import {
  Anchor,
  Button,
  Flex,
  Paper,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import LogoDark from "../../components/LogoDark";

const Register = () => {
  return (
    <Flex rowGap={15} align={"center"} direction={"column"}>
      <LogoDark />
      <Paper maw={320} bg={"transparent"}>
        <Text size="16px" fw={600} mb={5} ta={"center"}>
          Create Account
        </Text>
        <Text ta={"center"} mb={15}>
          Please provide your business email address and password to create your
          free account.
        </Text>
        <TextInput
          radius={8}
          placeholder="business email"
          label="Email Address"
          mb={15}
        />
        <PasswordInput
          radius={8}
          label="Password"
          placeholder="enter password"
          mb={15}
        />
        <Button radius={8} mb={20} fw={500} color="#1D212C" fullWidth>
          Create Account
        </Button>

        <Text ta={"center"}>
          Already have an account?{"  "}
          <Anchor href="/auth/login" c={"#1D212C"} underline="never" fw={600}>
            Login
          </Anchor>
        </Text>
      </Paper>
    </Flex>
  );
};

export default Register;
