import { Flex } from "@mantine/core";
import { menuItems } from "../../data/menu";
import SiteNavbarItem from "./SiteNavbarItem";
import { useLocation } from "react-router-dom";

const SiteNavbar = () => {
  const location = useLocation();
  return (
    <Flex direction={"column"} rowGap={3}>
      
      {menuItems.map((mi, i) => {
        return <SiteNavbarItem path={location.pathname} item={mi} key={i} />;
      })}
    </Flex>
  );
};

export default SiteNavbar;
