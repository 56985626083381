import { IconEmpathize } from "@tabler/icons-react";
import { Anchor, Flex, Text } from "@mantine/core";

const LogoDark: React.FC<{ href?: string }> = ({ href }) => {
  return (
    <Anchor underline="never" href={href ? href : undefined}>
      <Flex align={"center"} columnGap={3}>
        <IconEmpathize color="#E54A0C" />
        <Text size="22px" fw={600} c={"#E54A0C"}>
          Oil & Gas
        </Text>
        <Text size="22px" fw={600} c={"#1D212C"}>
          Talent
        </Text>
      </Flex>
    </Anchor>
  );
};

export default LogoDark;
