import cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import { IUser } from "../interfaces/user";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const AuthContext = createContext<{
  user: IUser | null;
  login(ud: IUser): void;
  logout(): void;
}>({
  user: null,
  login(ud) {},
  logout() {},
});

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userData, setUserData] = useState<IUser | null>(null);
  const navigate = useNavigate();

  const login = (ud: IUser) => {
    cookies.set("employer", JSON.stringify(ud), {
      expires: moment(new Date()).add("M", 2).toDate(),
    });
    navigate("/");
  };

  const logout = () => {
    cookies.remove("employer");
    navigate("/auth/login");
  };

  useEffect(() => {
    const ud =
      cookies.get("employer") === undefined ||
      typeof cookies.get("employer") === "undefined"
        ? null
        : JSON.parse(cookies.get("employer")!);
    setUserData(ud);
  }, []);

  return (
    <AuthContext.Provider value={{ user: userData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
