import { IconEmpathize } from "@tabler/icons-react";
import { Anchor, Flex, Text } from "@mantine/core";

const Logo: React.FC<{ href?: string }> = ({ href }) => {
  return (
    <Anchor underline="never" href={href ? href : undefined}>
      <Flex align={"center"} columnGap={3}>
        <IconEmpathize color="#FAD958" />
        <Text size="18px" fw={600} c={"#FAD958"}>
          Oil & Gas
        </Text>
        <Text size="18px" fw={600} c={"white"}>
          Talent
        </Text>
      </Flex>
    </Anchor>
  );
};

export default Logo;
