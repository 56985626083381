import { Anchor, Flex, UnstyledButton } from "@mantine/core";
import { IMenuItem } from "../../interfaces/menu";

const SiteNavbarItem: React.FC<{ item: IMenuItem; path: string }> = ({
  item,
  path,
}) => {
  return (
    <Anchor underline="never" href={item.href}>
      <UnstyledButton
        p={"xs"}
        className={path === item.href ? "menu-item active" : "menu-item"}
      >
        <Flex columnGap={5} align={"center"}>
          {item.icon}
          {item.title}
        </Flex>
      </UnstyledButton>
    </Anchor>
  );
};

export default SiteNavbarItem;
