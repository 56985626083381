import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AuthProvider from "./contexts/AuthProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MantineProvider
    theme={{
      fontFamily: "Inter",
      fontSizes: { xl: "14px", lg: "14px", md: "14px", sm: "14px", xs: "14px" },
    }}
  >
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </MantineProvider>
);
