import { Burger, Flex } from "@mantine/core";
import Logo from "../Logo";

const SiteHeader: React.FC<{ opened: boolean; toggle(): void }> = ({
  opened,
  toggle,
}) => {
  return (
    <Flex h={"100%"} px={"md"} align={"center"} justify={"space-between"}>
      <Logo href="/" />
      <Burger
        hiddenFrom="sm"
        opened={opened}
        onClick={toggle}
        size={"sm"}
        color="white"
      />
    </Flex>
  );
};

export default SiteHeader;
