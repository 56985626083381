import { AppShell } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SiteNavbar from "./components/navbar/SiteNavbar";
import SiteHeader from "./components/header/SiteHeader";
import { authRoutes } from "./routest";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./contexts/AuthProvider";

const App = () => {
  const authContext = useContext(AuthContext);
  const [opened, { toggle }] = useDisclosure();
  const [mount, setMount] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      typeof authContext !== "undefined" &&
      authContext.user !== null &&
      location.pathname.includes("auth")
    ) {
      navigate("/");
    }

    if (
      (typeof authContext === "undefined" || authContext.user === null) &&
      !location.pathname.includes("auth")
    ) {
      navigate("/auth/login");
    }
  }, [authContext]);

  useEffect(() => {
    if (authContext !== undefined && typeof authContext !== "undefined") {
      setMount(true);
    }
  }, [authContext]);

  if (!mount) {
    return null;
  } else {
    if (location.pathname.includes("auth")) {
      return (
        <div className="auth-wrapper">
          <Routes>
            {authRoutes.map((aRoute, i) => {
              return (
                <Route
                  element={aRoute.element}
                  key={aRoute.path}
                  path={aRoute.path}
                />
              );
            })}
          </Routes>
        </div>
      );
    } else {
      return (
        <AppShell
          header={{ height: 60 }}
          navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
          padding="md"
        >
          <AppShell.Header withBorder={false} bg={"#1D212C"}>
            <SiteHeader opened={opened} toggle={toggle} />
          </AppShell.Header>
          <AppShell.Navbar p="md">
            <SiteNavbar />
          </AppShell.Navbar>
          <AppShell.Main>
            <Routes>
              {authRoutes.map((aRoute, i) => {
                return (
                  <Route
                    element={aRoute.element}
                    key={aRoute.path}
                    path={aRoute.path}
                  />
                );
              })}
            </Routes>
          </AppShell.Main>
        </AppShell>
      );
    }
  }
};

export default App;
