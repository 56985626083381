import {
  IconBriefcase,
  IconHome,
  IconSettings,
  IconSettings2,
  IconUsersGroup,
} from "@tabler/icons-react";
import { IMenuItem } from "../interfaces/menu";

export const menuItems: IMenuItem[] = [
  { href: "/", title: "Home", icon: <IconHome size={18} /> },
  { href: "/jobs", title: "Job Ads", icon: <IconBriefcase size={18} /> },
  {
    href: "/talent-pool",
    title: "Talent Pool",
    icon: <IconUsersGroup size={18} />,
  },
  {
    href: "/company",
    title: "Company Settings",
    icon: <IconSettings2 size={18} />,
  },
  {
    href: "/settings",
    title: "User Settings",
    icon: <IconSettings size={18} />,
  },
];
